import React, { useState } from "react";
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SvgIcon,
} from "@material-ui/core";
import { useRootSelector } from "../../store/utils";
import { useDispatch } from "react-redux";
import {
    getWrongNetwork,
} from "../../store/Gambler/gambler.selector";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Transition } from "./DialogTransition";
import WesternTextureBg from "../../images/background2.jpg";
import CowboyRegisterImg from "../../images/cowboy-register.png";
import {ReactComponent as MetamaskFoxIcon} from "../../images/metamask-fox.svg";
import {switchNetwork} from "../../ethereum/index";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            borderRadius: 16,
        },
        dialogTitle: {
            padding: theme.spacing(2, 2, 2, 3),
            color: theme.palette.text.secondary,
            backgroundColor: "rgba(0,0,0,.8)",
        },
        dialogContent: {
            padding: theme.spacing(2, 2, 2, 2),
            backgroundImage: `url(${WesternTextureBg})`,
            backgroundSize: 'cover',
        },
        dialogText: {
            padding: theme.spacing(1, 0, 0, 1),
        },
        dialogActions: {
            padding: theme.spacing(1, 3, 1, 1),
            backgroundColor: "rgba(0,0,0,.8)",
        },
        image: {
            width: '100%',
        },
    })
);

export const WrongNetwork = () => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const isWrongNetwork = useRootSelector(getWrongNetwork);

    return (
        <Dialog
            open={isWrongNetwork}
            TransitionComponent={Transition}
            PaperProps={{ className: styles.dialog }}
        >
            <DialogTitle className={styles.dialogTitle}>🤠 Wrong network, Gunslinger!</DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <DialogContentText className={styles.dialogText}>
                    <span>☀️ Feel the tension rise as the sun sets on the horizon. <br/></span>
                    <span>🌵 The stakes are high, and the tumbleweeds are rolling. <br/></span>
                    <span>💰 Will you ride into the sunset with pockets jingling, or will you be left in the dust? <br/><br/></span>
                    <img className={styles.image} src={CowboyRegisterImg} alt="Cowboy register" />
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={switchNetwork}
                    startIcon={
                        <SvgIcon
                            fontSize="large"
                            component={MetamaskFoxIcon}
                            viewBox="0 0 318.6 318.6"
                        />
                    }
                >
                    Change network
                </Button>
            </DialogActions>
        </Dialog>
    );
};
