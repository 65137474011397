import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import { Chip, Container, Grid, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, Theme } from "@material-ui/core/styles";

import { useRootSelector } from "../../store/utils";
import { fetchGameAddresses } from "../../store/Game/game.slice";
import { useListeners } from "../../hooks/useListeners";
import {
  fetchAddress,
  setWithdrawProcess,
  setFaucetProcess,
} from "../../store/Gambler/gambler.slice";
import { CreateGameFabButton } from "../../components/Games/CreateGameFabButton";
import {
  getGambler,
  getWalletConnected,
} from "../../store/Gambler/gambler.selector";
import { formatShortAddress } from "../../shared/utils";
import { GamesContent } from "../../components/Games/GamesContent";

// Icons
import FaceIcon from "@material-ui/icons/Face";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import DnsIcon from "@material-ui/icons/Dns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: "auto",
      bottom: 0,
    },
    mainContainer: {
      paddingBottom: theme.spacing(12),
    },
  })
);

export const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const gambler = useRootSelector(getGambler);
  const isWalletConnected = useRootSelector(getWalletConnected);
  const gamblerBalance = gambler && ethers.utils.formatEther(gambler.balance);

  useListeners();

  useEffect(() => {
    dispatch(fetchAddress());
  }, []);

  useEffect(() => {
    if (isWalletConnected) {
      dispatch(fetchGameAddresses());
    }
  }, [isWalletConnected]);

  const handleWithdraw = () => dispatch(setWithdrawProcess(true));

  const handleFaucet = () => dispatch(setFaucetProcess(true));

  return (
    <React.Fragment>
      <Container className={classes.mainContainer}>
        <GamesContent />
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar variant="dense">
          <CreateGameFabButton />
          {gambler ? (
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Chip
                  icon={<FaceIcon />}
                  label={gambler.name}
                  clickable
                  color="secondary"
                />
              </Grid>
              <Grid item>
                <Tooltip
                  arrow
                  title="This is your casino contract address, which will be credited when you win a duel. Withdraw your bounty from the wallet icon in the navigation bar."
                >
                  <Chip
                    icon={<DnsIcon />}
                    label={`Dueler Addr: ${formatShortAddress(gambler.address)}`}
                    clickable
                    color="secondary"
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Chip
                  icon={<EmojiEvents />}
                  label={`Duels Won: ${gambler.gamesWon}`}
                  clickable
                  color="secondary"
                />
              </Grid>
              {Number(gamblerBalance) ? <Grid item>
                <Tooltip
                  arrow
                  title="This is your claimable balance. Click to withdraw."
                >
                  <Chip
                    onClick={handleWithdraw}
                    icon={<AccountBalanceWalletIcon />}
                    label={`${gamblerBalance} GUN`}
                    clickable
                    color="secondary"
                  />
                </Tooltip>
                </Grid>: null}
                <Grid item>
                    <Tooltip
                        arrow
                        title="Need GUN? Claim from our faucet!"
                    >
                        <Chip
                            onClick={handleFaucet}
                            icon={<InvertColorsIcon />}
                            label="Faucet"
                            clickable
                            color="secondary"
                        />
                    </Tooltip>
                </Grid>
            </Grid>
          ) : null}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
