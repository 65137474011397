import { useDispatch } from "react-redux";
import { casino, getEthereum, provider } from "../ethereum";
import { fetchGameByAddress, stopCreatingGame } from "../store/Game/game.slice";
import { useEffect } from "react";
import {
  clearGambler,
  fetchAddress,
  setNeedsRegister,
  setWalletConnected,
} from "../store/Gambler/gambler.slice";
import {setCreateGameDialog, showAlert, wrongNetwork} from "../store/Dialogs/dialogs.slice";
import {AlertType} from "../store/Dialogs/dialogs.model";

export const useListeners = () => {
  const dispatch = useDispatch();
  const ethereum = getEthereum();

  if (!ethereum) {
    throw new Error("Metamask is not installed");
  }

  useEffect(() => {
    casino.on("newGame", (gameAddress: string) => {
      dispatch(setCreateGameDialog(false));
      dispatch(fetchGameByAddress(gameAddress));
      dispatch(stopCreatingGame());
    });

    ethereum.on("accountsChanged", (accounts: string[]) => {
      if (!accounts.length) {
        dispatch(clearGambler());
        dispatch(setWalletConnected(false));
        dispatch(setNeedsRegister(false));
        return
      }
      dispatch(fetchAddress());
      dispatch(clearGambler());
      dispatch(setWalletConnected(true));
    });

    provider.on("network", (newNetwork, oldNetwork) => {
      console.log(newNetwork, oldNetwork);
      if (newNetwork.chainId !== 2812069) {
        dispatch(wrongNetwork(true));
        dispatch(showAlert({
          type: AlertType.warning,
          message: "Wrong Network",
        }))
      } else {
        dispatch(wrongNetwork(false));
      }
      if (oldNetwork) {
        window.location.reload();
      }
    });

    return () => {
      casino.removeAllListeners();
    };
  }, []);
};
