import React from "react";
import { Game } from "../../store/Game/game.models";
import { ethers } from "ethers";
import { useRootSelector } from "../../store/utils";
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { useGameContract } from "../../hooks/useGameContract";
import { getGamblerAddress } from "../../store/Gambler/gambler.selector";
import { useGameListener } from "../../hooks/useGameListeners";
import { isGamblerAPlayer } from "../../store/Game/game.utils";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import PVPIcon from "@material-ui/icons/Group";
import VideogameIcon from "@material-ui/icons/VideogameAsset";
import { formatShortAddress, isGamblerWinner } from "../../shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
      backgroundColor: "#263238",
      borderRadius: 16,
    },
    moneyIcon: {
      color: "rgb(50,205,50)",
    },
    walletIcon: {
      color: "rgb(255,255,255)",
    },
    pvpIcon: {
      color: "rgb(255,255,255)",
    },
    moneyOffIcon: {
      color: "rgb(255,0,0)",
    },
    gameID: {
      color: 'grey',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }
  })
);

type GameProps = {
  game: Game;
};

const GameItemIcon: React.FC<GameProps> = ({ game }) => {
  const styles = useStyles();
  const gamblerAddress = useRootSelector(getGamblerAddress);

  if (game.finished) {
    if (isGamblerWinner(game, gamblerAddress || ""))
      return (<svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="32"
          height="32"
          x="0"
          y="0"
          viewBox="0 0 447.981 447.981"
        >
          <g>
            <path
              d="m111.965 192.107-19.568-.016C52.525 225.275-1.443 277.467.029 338.411c.592 25.552 22.4 45.584 47.952 45.584h80c17.68 0 32-14.32 32-32v-80c0-11.808 6.48-22.016 16-27.568v-4.432c0-23.216-22.432-47.888-64.016-47.888z"
              fill="#32cd32"
              opacity="1"
              data-original="#000000"
            ></path>
            <path
              d="M431.981 96.091v-20.88c0-9.984-12.064-14.976-19.12-7.92l-28.784 28.784-176.64-.064c-17.584 0-33.216 9.936-42.144 25.072-1.056 1.776-2.288 3.296-3.408 4.992-8.688-8.688-20.688-14.08-33.952-14.08H96.125c-8.864 0-16.064 7.184-16.064 16.064s7.2 16.064 16.064 16.064h15.776c8.832 0 16 7.168 16 16v2.96c-3.824 3.44-9.36 7.872-15.84 13.04 50.64.032 79.92 31.888 79.92 63.872v8c0 39.76 32.24 72 72 72s72-32.24 72-72V159.979h112V96.091h-16zm-224 31.904h96v16h-96v-16zm0 64v-16h96v16h-96zm104.001 56c0 26.464-21.536 48-48 48-24.336 0-44.272-18.256-47.376-41.76-.112-7.376 3.104-14.24 15.376-14.24h7.936a54.637 54.637 0 0 0 18.16 33.968c2.816 2.48 7.36-.4 6.368-4.016-2.528-9.152-2.656-19.264.656-29.952h46.88v8z"
              fill="#32cd32"
              opacity="1"
              data-original="#000000"
            ></path>
          </g>
        </svg>
      );
    if (gamblerAddress && isGamblerAPlayer(game, gamblerAddress))
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="32"
          height="32"
          x="0"
          y="0"
          viewBox="0 0 447.981 447.981"
        >
          <g>
            <path
              d="m111.965 192.107-19.568-.016C52.525 225.275-1.443 277.467.029 338.411c.592 25.552 22.4 45.584 47.952 45.584h80c17.68 0 32-14.32 32-32v-80c0-11.808 6.48-22.016 16-27.568v-4.432c0-23.216-22.432-47.888-64.016-47.888z"
              fill="#ff0000"
              opacity="1"
              data-original="#000000"
            ></path>
            <path
              d="M431.981 96.091v-20.88c0-9.984-12.064-14.976-19.12-7.92l-28.784 28.784-176.64-.064c-17.584 0-33.216 9.936-42.144 25.072-1.056 1.776-2.288 3.296-3.408 4.992-8.688-8.688-20.688-14.08-33.952-14.08H96.125c-8.864 0-16.064 7.184-16.064 16.064s7.2 16.064 16.064 16.064h15.776c8.832 0 16 7.168 16 16v2.96c-3.824 3.44-9.36 7.872-15.84 13.04 50.64.032 79.92 31.888 79.92 63.872v8c0 39.76 32.24 72 72 72s72-32.24 72-72V159.979h112V96.091h-16zm-224 31.904h96v16h-96v-16zm0 64v-16h96v16h-96zm104.001 56c0 26.464-21.536 48-48 48-24.336 0-44.272-18.256-47.376-41.76-.112-7.376 3.104-14.24 15.376-14.24h7.936a54.637 54.637 0 0 0 18.16 33.968c2.816 2.48 7.36-.4 6.368-4.016-2.528-9.152-2.656-19.264.656-29.952h46.88v8z"
              fill="#ff0000"
              opacity="1"
              data-original="#000000"
            ></path>
          </g>
        </svg>
      );
    return <PVPIcon className={styles.pvpIcon} fontSize="large" />;
  }
  return <VideogameIcon className={styles.walletIcon} fontSize="large" />;
};

export const GameItem: React.FC<GameProps> = ({ game }) => {
  const styles = useStyles();

  useGameListener(game);

  const gamblerAddress = useRootSelector(getGamblerAddress);
  const { play, isPlaying } = useGameContract(game);

  if (!game) {
    return <h4>Duel not found</h4>;
  }

  const imGambler =
    game.player1 === gamblerAddress || game.player2 === gamblerAddress;

  const secondaryText = game.finished
    ? `Duel won by: ${formatShortAddress(game.winner)} (aka ${game.winnerName})`
    : `Duel started by: ${formatShortAddress(game.player1)} (aka ${game.player1Name})`;
  const formattedBetAmount = ethers.utils.formatEther(game.betAmount);

  return (
    <React.Fragment>
      <ListItem className={styles.container}>
        <ListItemAvatar>
        <div className={styles.iconContainer}>
          <GameItemIcon game={game} />
          <div className={styles.gameID}># {game.id}</div>
        </div>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ style: { color: "white" } }}
          primary={`${formattedBetAmount} GUN`}
          secondary={secondaryText}
        />
        {!imGambler && !game.finished ? (
          <ListItemSecondaryAction>
            {isPlaying ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <IconButton
                color="secondary"
                onClick={play}
                edge="end"
                aria-label="play"
              >
                <PlayIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </React.Fragment>
  );
};
