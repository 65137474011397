import Typography from "@material-ui/core/Typography";
import { GamesList } from "./GamesList";
import React from "react";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { NoGames } from "./NoGames";
import { useRootSelector } from "../../store/utils";
import {
  getFinishedGames,
  getGamblerGames,
  getGamesLoading,
  getOpenGames,
} from "../../store/Game/game.selectors";
import { getGamblerLoading } from "../../store/Gambler/gambler.selector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: theme.spacing(2, 2, 0),
      fontWeight: 'bold',
    },
    grow: {
      flexGrow: 1,
    },
    noGameContainer: {
      margin: theme.spacing(16, 0),
    },
    gamesContainer: {
      display: 'flex',
      flex: 1,
    },
    myGamesWrapper: {
      flex: 1,
      margin: theme.spacing(0, 2),
    },
    otherGamesWrapper: {
      flex: 1,
      margin: theme.spacing(0, 2),
    },
  })
);

export const GamesContent = () => {
  const classes = useStyles();

  const gamblerGames = useRootSelector(getGamblerGames);
  const openGames = useRootSelector(getOpenGames);
  const finishedGames = useRootSelector(getFinishedGames);
  const areGamesLoading = useRootSelector(getGamesLoading);
  const isGamblerLoading = useRootSelector(getGamblerLoading);

  const myGamesPageSize = 5;
  const otherGamesPageSize = 7;
  const [openGamesPage, setOpenGamesPage] = React.useState(1);
  const [gamblerGamesPage, setGamblerGamesPage] = React.useState(1);
  const [finishedGamesPage, setFinishedGamesPage] = React.useState(1);
  const handleOpenGamesPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setOpenGamesPage(value);
  };

  const handleGamblerGamesPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setGamblerGamesPage(value);
  };

  const handleFinishedGamesPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setFinishedGamesPage(value);
  };

  const loading = areGamesLoading || isGamblerLoading;

  const showNoGames = !(
    gamblerGames.length +
    openGames.length +
    finishedGames.length
  );

  if (showNoGames && !loading)
    return (
      <div className={classes.noGameContainer}>
        <NoGames big />
      </div>
    );
  return (
    <>
      <div className={classes.gamesContainer}>
        <div className={classes.myGamesWrapper}>
          <Typography
            color="textSecondary"
            className={classes.text}
            variant="h5"
            gutterBottom
          >
            Open Duels
          </Typography>
          <GamesList
            loading={loading}
            games={openGames.slice((myGamesPageSize * (openGamesPage - 1)), (myGamesPageSize * openGamesPage))}
            />
          <Pagination
            page={openGamesPage}
            count={Math.ceil(openGames.length / myGamesPageSize)}
            color="secondary"
            onChange={handleOpenGamesPageChange}
          />
        <Typography
            color="textSecondary"
            className={classes.text}
            variant="h5"
            gutterBottom
          >
            My Duels
          </Typography>
          <GamesList 
            loading={loading}
            games={gamblerGames.slice((myGamesPageSize * (gamblerGamesPage - 1)), (myGamesPageSize * gamblerGamesPage))}
          />
          <Pagination
            page={gamblerGamesPage}
            count={Math.ceil(gamblerGames.length / myGamesPageSize)}
            color="secondary"
            onChange={handleGamblerGamesPageChange}
          />
        </div>
        <div className={classes.otherGamesWrapper}>
          <Typography
            color="textSecondary"
            className={classes.text}
            variant="h5"
            gutterBottom
          >
            Other PVP Duels
          </Typography>
          <GamesList
            loading={loading}
            games={finishedGames.slice((otherGamesPageSize * (finishedGamesPage - 1)), (otherGamesPageSize * finishedGamesPage))}
            />
          <Pagination
            page={finishedGamesPage}
            count={Math.ceil(finishedGames.length / otherGamesPageSize)}
            color="secondary"
            onChange={handleFinishedGamesPageChange}
          />
        </div>
      </div>  
    </>
  );
};
