import { RootState } from "../utils";

export function getNeedsRegister(state: RootState) {
  return state.gambler.needsRegister;
}

export function getWrongNetwork(state: RootState) {
  return state.dialogs.wrongNetwork;
}

export function getGamblerAddress(state: RootState) {
  return state.gambler.gambler?.address;
}

export function getGamblerName(state: RootState) {
  const name = state.gambler.gambler?.name;
  return name || 'User not yet registered';
}

export function getUserAddress(state: RootState) {
  return state.gambler.userAddress;
}

export function getCreatingGambler(state: RootState) {
  return state.gambler.creatingGambler;
}

export function getGambler(state: RootState) {
  return state.gambler.gambler;
}

export function getIsWithdrawing(state: RootState) {
  return state.gambler.withdrawing;
}

export function getWithdrawProcess(state: RootState) {
  return state.gambler.withdrawProcess;
}

export function getFaucetProcess(state: RootState) {
  return state.gambler.faucetProcess;
}

export function getWalletConnected(state: RootState) {
  return state.gambler.walletConnected;
}

export function getGamblerLoading(state: RootState) {
  return state.gambler.loading;
}

export function getMetamaskInstalled(state: RootState) {
  return state.gambler.metamaskInstalled;
}
