import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  withStyles,
} from "@material-ui/core";
import { useRootSelector } from "../../store/utils";
import { getIsCreateGameDialogOpen } from "../../store/Dialogs/dialogs.selector";
import { useDispatch } from "react-redux";
import { CurrencyInput } from "../Inputs/CurrencyInput";
import { getIsCreatingGame } from "../../store/Game/game.selectors";
import { createGame } from "../../store/Game/game.slice";
import { setCreateGameDialog } from "../../store/Dialogs/dialogs.slice";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Transition } from "./DialogTransition";
import CowboyFaceoffImg from "../../images/cowboy-faceoff.png";
import WesternTextureBg from "../../images/background2.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      padding: theme.spacing(2, 0),
    },
    titleSpan: {
      padding: 10,
    },
    dialog: {
      borderRadius: 16,
    },
    dialogTitle: {
      padding: theme.spacing(2, 2, 2, 3),
      color: theme.palette.text.secondary,
      backgroundColor: "rgba(0,0,0,.8)",
    },
    dialogContent: {
      padding: theme.spacing(2, 2),
      whiteSpace: "pre-line",
      backgroundImage: `url(${WesternTextureBg})`,
      backgroundSize: 'contain',
    },
    dialogActions: {
      padding: theme.spacing(1, 3, 1, 1),
      backgroundColor: "rgba(0,0,0,.8)",
    },
    paragraphs: {
      color: 'white',
    }
  })
);

export const CreateGameDialog = () => {
  const styles = useStyles();

  const isCreateGameDialogOpen = useRootSelector(getIsCreateGameDialogOpen);
  const isCreatingGame = useRootSelector(getIsCreatingGame);

  const dispatch = useDispatch();
  const [betAmount, setBetAmount] = useState<string>("0");

  const handleClose = () => dispatch(setCreateGameDialog(false));
  const handleCreation = () => {
    dispatch(createGame(betAmount));
  };

  return (
    <Dialog
      open={isCreateGameDialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ className: styles.dialog }}
    >
      <DialogTitle className={styles.dialogTitle}>Start a Duel</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <DialogContentText>
          <span>💰 Even Odds, Wild Bets. Russian Roulette, cowboy style.<br/></span>
          <span>In Two-Gun Tumble, it`s a 50/50 gamble. Each pull of the trigger brings the thrill of victory or the sting of defeat.<br/><br/></span>
          <span>Will you be the quick-draw champion, or will your opponent outgun you?</span>
          <img className={styles.image} src={CowboyFaceoffImg} alt="Cowboy faceoff" />
        </DialogContentText>
        <CurrencyInput
          disabled={isCreatingGame}
          onChange={(total) => setBetAmount(total)}
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          variant="contained"
          disabled={isCreatingGame}
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreatingGame || !Number(betAmount)}
          onClick={handleCreation}
          color="secondary"
        >
          Start Duel
        </Button>
      </DialogActions>
      {isCreatingGame ? <LinearProgress /> : null}
    </Dialog>
  );
};
